<template>
  <div ref="el">
    <h1 class="text-2xl font-semibold text-gray-900">
      Statistieken - Dashboard
    </h1>
    <div class="card flex flex-col gap-2">
      <div class="flex flex-col lg:flex-row justify-between gap-2">
        <UITabs
          v-model="tab"
          :tabs="tabs"
        />
      </div>
      <div class="inline-flex flex-col lg:flex-row gap-2 text-left">
        <UISelectStatsFilter
          v-model:day="filters.day"
          v-model:month="filters.month"
          v-model:year="filters.year"
          class="order-2 lg:order-1"
          type="DAGELIJKS"
          verberg-type
          verberg-alle-days
          verberg-alle-months
          verberg-alle-years
        />
        <UISelectFixed
          v-if="tabs[tab] !== 'Contacten'"
          v-model="filters.bedrijf_thema"
          type="BEDRIJVEN_THEMA"
          nullable
        />
        <UIInput
          v-if="tab <= 1"
          v-model="filters.avg_price"
          type="number"
          autocomplete="off"
          class="order-1 lg:order-2 lg:ml-2 lg:-mb-3 max-w-xs"
          label="Gem. verkoopprijs"
        />
      </div>
      <div
        v-if="tab <= 4"
        class="flex flex-row gap-4 items-start"
      >
        <UICheckbox
          v-model="vergelijken"
          label="Vergelijken?"
        />
        <UISelectStatsFilter
          v-if="vergelijken"
          v-model:day="filtersVergelijken.day"
          v-model:month="filtersVergelijken.month"
          v-model:year="filtersVergelijken.year"
          class="order-2 lg:order-1"
          type="DAGELIJKS"
          verberg-type
          verberg-alle-days
          verberg-alle-months
          verberg-alle-years
        />
      </div>

      <UIAlertInfo
        v-if="tab <= 2"
        tag="p"
      >
        <small v-if="tab == 0">Op basis van <b>vertrek/retour datum</b> (enkele ritten)</small>
        <small v-else-if="tab == 1">Op basis van <b>boekings datum</b> (volledige reservatie)</small>
        <small v-else-if="tab == 2">Op basis van <b>betaal datum</b> (betalingen)</small>
      </UIAlertInfo>

      <router-view v-slot="{ Component }">
        <KeepAlive :key="renderKey">
          <component
            :is="Component"
            :filters="filters"
            :render-key="renderKeyTab"
            :filters-vergelijken="vergelijken ? filtersVergelijken : null"
          />
        </KeepAlive>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useElementSize } from '@vueuse/core'

import UICheckbox from '@/components/UI/Input/Checkbox.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UITabs from '@/components/UI/Tabs.vue'
import UIAlertInfo from '@/components/UI/Text/AlertInfo.vue'

const route = useRoute()
const router = useRouter()

const today = new Date()

const filters = ref({
  day: today.getDate(),
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  avg_price: 141.67,
  bedrijf_thema: null,
})

const vergelijken = ref(false)
const filtersVergelijken = ref({
  day: today.getDate(),
  month: today.getMonth() + 1,
  year: today.getFullYear() - 1,
  avg_price: filters.value.avg_price,
  bedrijf_thema: null,
})

watch(() => filters.value, () => {
  filtersVergelijken.value = {
    ...filtersVergelijken.value,
    avg_price: filters.value.avg_price,
    bedrijf_thema: filters.value.bedrijf_thema,
  }
}, { deep: true })

watch(vergelijken, val => {
  if (!val) return
  filtersVergelijken.value = {
    ...filtersVergelijken.value,
    day: filters.value.day,
    month: filters.value.month,
    year: filters.value.year - 1,
  }
})

const tabs = [
  'Ritten',
  'Boekingen',
  'Betalingen',
  'Pipeline',
  'Klanten',
  'TopKlanten',
  'TopBedrijven',
  'Betaaltermijnen',
  'Contacten',
  'Personeel',
]

const tab = ref(Math.max(0, tabs.findIndex(t => route.name.includes(`StatsDashboard${t}`))))

watch(tab, () => {
  vergelijken.value = false
  router.push({ name: `StatsDashboard${tabs[tab.value]}` })
})

const el = ref(null)
const { width } = useElementSize(el)
const renderKeyTab = computed(() => `${width.value}-${tab.value}`)
const renderKey = computed(() => JSON.stringify({ f: filters.value, fv: filtersVergelijken.value, v: vergelijken.value }))
</script>
