<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Statistieken - Onderaanneming
  </h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs v-model="tab" :tabs="tabs" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
      />
    </div>
    <p v-if="isSimulatie" class="bg-blue-200 inline-flex self-start items-center gap-1 pr-4 px-2 py-1 rounded-sm">
      <i class="fas fa-info bg-blue-400 text-white w-5 h-5 rounded-full mr-2 text-xs"></i>
      <small>Op basis van simulatie indien alle ritten zouden uitgevoerd zijn via onderaanneming (aan 80% commisie)</small>
    </p>
    <KeepAlive :key="Object.values(filters).join('')">
      <component
        :is="Views[tab]"
        :key="tab"
        :filters="filters"
        :group-by-user="isGroupByUser"
      />
    </KeepAlive>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'

import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UITabs from '@/components/UI/Tabs.vue'
// import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'

const tabs = ['Uitgevoerd totaal', 'Uitgevoerd per onderaannemer', 'Simulatie']
const components = ['Uitgevoerd', 'Uitgevoerd', 'Simulatie']
const tab = ref(0)
const Views = computed(() => tabs.map((_, index) => defineAsyncComponent(() => import(`./${components[index]}.vue`))))
const isGroupByUser = computed(() => tab.value === 1)
const isSimulatie = computed(() => tab.value === 2)

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: null,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
})
</script>
