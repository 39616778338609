<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Statistieken - Enquetes
  </h1>
  <div class="grid gap-2">
    <div class="card flex flex-col gap-2">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
        v-model:user_id="filters.user_id"
        toon-chauffeurs
      />
    </div>

    <!-- <pre style="max-height: 600px; width: 100%; overflow-y: scroll;">{{  data?.statistieken  }}</pre> -->

    <div v-if="data?.statistieken?.length" :key="barValues.join('')" class="card">
      <h2>Scores verdeling</h2>
      <ChartsBar
        label="Enquetes"
        :categories="chartHeaders"
        :values="barValues"
        class="mx-auto"
        style="max-width: 800px; max-height: 300px;"
      />
    </div>

    <div v-if="data?.statistieken" class="card">
      <UITableResponsive :items="data.statistieken || []" :headers="tableHeaders" class="text-center">
        <template #item-Datum="{ item }">
          {{ item.day }} {{ item.month ? monthToString(item.month) : '' }} {{ item.year }} ({{ item.count }} Enquetes)
        </template>
        <template #item-Score_0="{ item }">
          {{ Number(item.count_0_heen || 0) + Number(item.count_0_retour || 0) }}
        </template>
        <template #item-Score_1="{ item }">
          {{ Number(item.count_1_heen || 0) + Number(item.count_1_retour || 0) }}
        </template>
        <template #item-Score_2="{ item }">
          {{ Number(item.count_2_heen || 0) + Number(item.count_2_retour || 0) }}
        </template>
        <template #item-Score_3="{ item }">
          {{ Number(item.count_3_heen || 0) + Number(item.count_3_retour || 0) }}
        </template>
        <template #item-Score_4="{ item }">
          {{ Number(item.count_4_heen || 0) + Number(item.count_4_retour || 0) }}
        </template>
        <template #item-Score_5="{ item }">
          {{ Number(item.count_5_heen || 0) + Number(item.count_5_retour || 0) }}
        </template>
        <template #item-Score_6="{ item }">
          {{ Number(item.count_6_heen || 0) + Number(item.count_6_retour || 0) }}
        </template>
        <template #item-Score_7="{ item }">
          {{ Number(item.count_7_heen || 0) + Number(item.count_7_retour || 0) }}
        </template>
        <template #item-Score_8="{ item }">
          {{ Number(item.count_8_heen || 0) + Number(item.count_8_retour || 0) }}
        </template>
        <template #item-Score_9="{ item }">
          {{ Number(item.count_9_heen || 0) + Number(item.count_9_retour || 0) }}
        </template>
        <template #item-Score_10="{ item }">
          {{ Number(item.count_10_heen || 0) + Number(item.count_10_retour || 0) }}
        </template>
      </UITableResponsive>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'

import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
// import UISelectFixed from '@/components/UI/Select/Fixed.vue'
// import UISelectMinMax from '@/components/UI/Select/MinMax.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import { monthToString } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'

const ChartsBar = defineAsyncComponent(() => import('@/components/Charts/Bar.vue'))

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: today.getDate(),
  month: today.getMonth() + 1,
  year: 2022, // today.getFullYear(),
})

const { data } = useGetApi('/api/dashboard/stats/enquetes', filters, { watch: true })

const barValues = computed(() => {
  const result = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  data.value?.statistieken.forEach(el => {
    result[0] = (result[0] || 0) + Number(el.count_0_heen || 0) + Number(el.count_0_retour || 0)
    result[1] = (result[1] || 0) + Number(el.count_1_heen || 0) + Number(el.count_1_retour || 0)
    result[2] = (result[2] || 0) + Number(el.count_2_heen || 0) + Number(el.count_2_retour || 0)
    result[3] = (result[3] || 0) + Number(el.count_3_heen || 0) + Number(el.count_3_retour || 0)
    result[4] = (result[4] || 0) + Number(el.count_4_heen || 0) + Number(el.count_4_retour || 0)
    result[5] = (result[5] || 0) + Number(el.count_5_heen || 0) + Number(el.count_5_retour || 0)
    result[6] = (result[6] || 0) + Number(el.count_6_heen || 0) + Number(el.count_6_retour || 0)
    result[7] = (result[7] || 0) + Number(el.count_7_heen || 0) + Number(el.count_7_retour || 0)
    result[8] = (result[8] || 0) + Number(el.count_8_heen || 0) + Number(el.count_8_retour || 0)
    result[9] = (result[9] || 0) + Number(el.count_9_heen || 0) + Number(el.count_9_retour || 0)
    result[10] = (result[10] || 0) + Number(el.count_10_heen || 0) + Number(el.count_10_retour || 0)
  })
  return result
})

const chartHeaders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => `Score ${n}`)
const tableHeaders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => `Score_${n}`)
tableHeaders.unshift('Datum')
</script>
