<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Statistieken - Chauffeurs
  </h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs v-model="tab" :tabs="tabs" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
      />
      <UISelectWagen
        v-model="filters.wagen_id"
        active
        label
        nullable
        use-query
        class="max-w-xs"
      />
    </div>
    <KeepAlive :key="Object.values(filters).join('')">
      <component :is="Views[tab]" :filters="filters" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'

import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import UITabs from '@/components/UI/Tabs.vue'

const tabs = ['Kilometerstanden', 'Gereden']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab}.vue`))))

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: null,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  wagen_id: null,
})
</script>
