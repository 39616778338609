<template>
  <h1 class="text-2xl font-semibold text-gray-900">
    Statistieken - Chauffeurs
  </h1>
  <div class="card flex flex-col gap-2">
    <div class="flex flex-col md:flex-row justify-between gap-2">
      <UITabs v-model="tab" :tabs="tabs" />
    </div>
    <div class="flex flex-row gap-4">
      <UISelectStatsFilter
        v-model:type="filters.type"
        v-model:day="filters.day"
        v-model:month="filters.month"
        v-model:year="filters.year"
        v-model:user_id="filters.user_id"
        :toon-chauffeurs="tab !== 0"
        :verberg-type="tab === 0"
        :verberg-day="tab === 0"
        :verberg-alle-months="tab === 0"
      />
      <!-- @TODO: Wekelijkse stats -->
      <!-- v-model:week="filters.week" -->
      <!-- toon-week -->
    </div>
    <UIAlertInfo v-if="tab == 0" tag="small">
      Cache van maximaal 15 minuten, gesorteerd op 'totaal'
    </UIAlertInfo>
    <KeepAlive>
      <component :is="Views[tab]" :filters="filters" />
    </KeepAlive>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'

import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UITabs from '@/components/UI/Tabs.vue'
import UIAlertInfo from '@/components/UI/Text/AlertInfo.vue'

const tabs = ['Prestaties', 'Lonen', 'Toekenning']
const tab = ref(0)
const Views = computed(() => tabs.map(tab => defineAsyncComponent(() => import(`./${tab}.vue`))))

watch(tab, () => {
  if (tab.value === 0) {
    filters.value.type = 'MAANDELIJKS'
    filters.value.day = null
    filters.value.month = today.getMonth() + 1
    filters.value.year = today.getFullYear()
    filters.value.user_id = null
  }
})

const today = new Date()
const filters = ref({
  type: 'MAANDELIJKS',
  day: null,
  // week: null,
  month: today.getMonth() + 1,
  year: today.getFullYear(),
  user_id: null,
})
</script>
